import { makeStyles } from '@mui/styles'

import Link from '@mui/material/Link'

import Solution from './Solution'
import { Typography } from '@mui/material'

import { CodeBlock } from '@atlaskit/code'

const cases = [
  {
    ratings: [1, 2, 2],
    answer: 4,
  },
  {
    ratings: [2, 4, 2, 6, 1, 7, 8, 9, 2, 1],
    answer: 19,
  },
  {
    ratings: [2, 4, 3, 5, 2, 6, 4, 5],
    answer: 12,
  },
  {
    ratings: [1, 2, 3, 4, 3, 2, 1],
    answer: 16,
  },
]

const code = `
def optimalCandies(n,arr):
    # Start with an array of 1's
    candies = [1]*n

    # Loop through ratings forward
    for i in range(n-1):
        # If the next rating is greater than the current rating
        if arr[i+1]>arr[i]:
            # Then, next candy amount = curr candy amount + 1
            candies[i+1] = candies[i]+1

    # Loop through ratings in reverse
    for i in range(n-1,0,-1):
        # If the previous rating is > the current rating...
        # *and* previous candy amount is <= current candy amount
        if arr[i-1]>arr[i] and candies[i-1]<=candies[i]:
            # Then, the prev candy amount = current candy amount + 1
            candies[i-1] = candies[i]+1

    # Return the total candies distributed to students
    return sum(candies)
`

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },

    header: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    codeBlock: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: theme.spacing(2),
    },

    solutions: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      // justifyContent: 'center',
      // alignItems: 'center',
    },
  }),
  {
    name: 'Homepage',
  }
)

const Homepage = props => {
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h3">Candies Solution and Table Visualization</Typography>
        <Typography variant="h5">
          <Link target="_blank" href="https://www.hackerrank.com/challenges/candies">
            https://www.hackerrank.com/challenges/candies
          </Link>
        </Typography>
      </div>
      <div className={classes.codeBlock}>
        <CodeBlock language="python" showLineNumbers={false} text={code} />
        <Typography>
          Credit:&nbsp;
          <Link href="https://www.thepoorcoder.com/hackerrank-candies-solution/" target="_blank">
            https://www.thepoorcoder.com/hackerrank-candies-solution/
          </Link>
        </Typography>
      </div>

      <div className={classes.solutions}>
        {cases.map((item, caseIndex) => {
          return (
            <Solution
              ratings={item.ratings}
              answer={item.answer}
              key={caseIndex}
              index={caseIndex}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Homepage
