import { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'

import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      // flex: 1,
      flexDirection: 'column',
      // width: '100vw',
      // height: '100vh',
      // maxWidth: '100%', // Prevent unwanted horizontal scroll: https://stackoverflow.com/a/23367686/11903320
      // padding: 0,
      // margin: 0,
      // background: '#121212',
    },

    ratings: {
      display: 'flex',
      flex: 1,
      fontSize: 36,
    },

    tableRoot: {
      width: 'auto !important',
    },

    testCaseContainer: {
      margin: theme.spacing(1),
    },
  }),
  {
    name: 'Solution',
  }
)

const Solution = props => {
  const classes = useStyles(props)

  const { ratings, answer, index } = props

  const [forwardLoopCandyList, setForwardLoopCandyList] = useState([])
  const [finalCandyList, setFinalCandyList] = useState([])
  const [result, setResult] = useState(0)

  useEffect(() => {
    // Solution ported to Javascript below
    // Credit: https://www.thepoorcoder.com/hackerrank-candies-solution/
    const n = ratings.length
    let candyList = new Array(n).fill(1)

    // Loop through ratings forward
    for (let i = 0; i < n; i++) {
      // If the next rating is greater than the current rating
      if (ratings[i + 1] > ratings[i]) {
        // Then, next candy amount = curr candy amount + 1
        candyList[i + 1] = candyList[i] + 1
      }
    }

    setForwardLoopCandyList([...candyList])

    // Loop through ratings in reverse
    for (let i = n - 1; i >= 0; i -= 1) {
      // If the previous rating is > the current rating...
      // *and* previous candy amount is <= current candy amount
      if (ratings[i - 1] > ratings[i] && candyList[i - 1] <= candyList[i]) {
        // Then, the prev candy amount = current candy amount + 1
        candyList[i - 1] = candyList[i] + 1
      }
    }

    setFinalCandyList([...candyList])
    setResult([...candyList].reduce((a, b) => a + b, 0))
  }, [ratings])

  return (
    <div className={classes.testCaseContainer}>
      <TableContainer component={Paper}>
        <Table
          size="small"
          classes={{
            root: classes.tableRoot,
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell key="topLeftCorner">Test Case {index + 1}</TableCell>
              {ratings.map((item, ratingIndex) => {
                return <TableCell key={ratingIndex}></TableCell>
              })}
              <TableCell key="total">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="Ratings">
              <TableCell key="rowHeader">Student Ratings:</TableCell>
              {ratings.map((item, ratingIndex) => {
                return <TableCell key={ratingIndex}>{item}</TableCell>
              })}
            </TableRow>
            <TableRow key="Forward Loop">
              <TableCell key="rowHeader">After Forward Loop:</TableCell>
              {forwardLoopCandyList.map((item, ratingIndex) => {
                return <TableCell key={ratingIndex}>{item}</TableCell>
              })}
              <TableCell key="forwardLoopSum">
                {forwardLoopCandyList.reduce((a, b) => a + b, 0)}
              </TableCell>
            </TableRow>
            <TableRow key="Candies">
              <TableCell key="rowHeader">Final Candy Distribution:</TableCell>
              {finalCandyList.map((item, candyIndex) => {
                return <TableCell key={candyIndex}>{item}</TableCell>
              })}
              <TableCell
                key="forwardLoopSum"
                style={{
                  backgroundColor: result === answer ? 'lightgreen' : 'lightred',
                }}
              >
                {finalCandyList.reduce((a, b) => a + b, 0)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default Solution
